@use "../config" as *;

.waf-kabaddiscorecard {
  padding: 0;
  // margin: var(--half-space-negative);

  .waf-head {
    .head-wrap {
      width: var(--container-max-width);
      margin: auto;
      padding: var(--full-space) var(--full-space) 0;
    }

    .title {
      text-transform: capitalize;
    }
  }

  .card-section {
    background-color: var(--kabaddi-secondary-color);
    // mix-blend-mode: lighten;
    color: var(--white-color);
    border-bottom: 0.4rem solid var(--kabaddi-primary-color);
    @include thumb-clip(88%, 91%);

    .card {
      &-header {
        background-color: var(--pro-kabaddi-secondary-color);
        padding: var(--full-space);
        border-bottom: 0.1rem solid rgba(var(--white-color-rgb), 0.15);
        @include flex-config(flex, null, space-between, null);
      }

      &-content {
        background: rgba(var(--kabaddi-accent-color-rgb), 0.4)
          url("/static-assets/images/cssimages/mast-head-bg-mob.png?v=#{$image-version}") no-repeat center center;
        padding: 0 var(--full-space);
        height: 18rem;
        @include flex-config(flex, null, null, center);
      }

      &-footer {
        font-size: 1.4rem;
        font-family: $font-bold;
        text-align: center;
        padding: var(--full-space) 0;
        // border-top: 0.1rem solid rgba(var(--white-color-rgb), 0.15);
        border-bottom: 0.1rem solid rgba(var(--white-color-rgb), 0.15);
      }
    }

    .head-wrap {
      flex-basis: 65%;
      max-width: 65%;
      flex-wrap: wrap;

      .title,
      .matchinfo,
      .match-time {
        font-size: 1.2rem;
        line-height: 2rem;
        color: var(--white-color);
        font-family: $font-medium;
      }
    }

    .status {
      font-size: 1.1rem;
      padding: 0 var(--half-space);
      height: 2rem;
      @include flex-config(flex, null, null, center);
    }

    .team {
      // background-color: var(--kabaddi-primary-color);
      flex-basis: 35%;
      // height: 6.5rem;
      @include flex-config(flex, null, flex-start, center);

      &-logo {
        width: 9rem;
        height: auto;
        // background-color: var(--white-color);
        // border-radius: 100%;
        padding: var(--half-space);
        object-fit: contain;
      }

      &-name {
        order: 1;
        font-size: 1.4rem;
        text-align: center;
        font-family: $font-bold;
        flex-basis: 60%;
        max-width: 60%;
        // padding-right: var(--half-space);
        // margin-left: var(--half-space);
        &.shortname {
          display: none;
        }
      }

      &-a {
        border-radius: 3.5rem 0 0 3.5rem;
        margin-right: -0.1rem;
        .team-info {
          @include flex-config(flex, column, flex-start, center);
        }
      }

      &-b {
        border-radius: 0 3.5rem 3.5rem 0;
        margin-left: -0.1rem;
        .team-info {
          @include flex-config(flex, column, flex-end, center);
        }
      }

      .team-score {
        .score {
          color: var(--white-color);
        }

        &.won {
          .score {
            color: var(--kabaddi-primary-color);
          }
        }
      }
    }

    .team-info {
      height: 100%;
      width: 100%;
    }

    .team-timer {
      flex-basis: 30%;
      position: relative;
      @include flex-config(flex, null, center, null);

      .time-text {
        font-size: 1.1rem;
        font-family: $font-bold;
        color: var(--kabaddi-secondary-color);
        @include center(horizontal);
      }
      .timer {
        position: relative;
        color: var(--kabaddi-secondary-color);
        background-color: var(--dark-grey-color);
        font-size: 1.2rem;
        line-height: 1;
        text-align: center;
        margin: 0 auto;
        width: 5rem;
        height: 5rem;
        border: 4px solid var(--kabaddi-accent-color);
        border-radius: 50%;
        filter: drop-shadow(0 0.3rem 0.1rem rgba(var(--black-color-rgb), 0.2));
        @include flex-config(flex, null, center, center);
      }
    }

    .team-score {
      .score {
        font-size: 2.5rem;
        font-family: $font-bold;
        color: var(--kabaddi-secondary-color);
      }
    }

    .team-mat-info {
      height: 5rem;
      @include flex-config(flex, null, center, center);

      .mat-player-list {
        @include flex-config(flex, null, null, center);
      }

      .player-item {
        position: relative;
        margin: 0 calc(var(--half-space) / 2);
        &.active {
          .mat-status {
            background-color: var(--kabaddi-accent-color);
          }
        }
      }

      .mat-status {
        width: 0.7rem;
        height: 0.7rem;
        background: var(--kabaddi-primary-color);
        border-radius: 100%;
        overflow: hidden;

        &:hover {
          background-color: var(--kabaddi-accent-color);

          & ~ .mat-content {
            display: block;
          }
        }
      }

      .mat-content {
        display: none;
        width: 9.5rem;
        position: absolute;
        bottom: 2rem;
        left: -2rem;
        background-color: var(--white-color);
        padding: var(--half-space);
        overflow: unset;

        .head-title {
          color: var(--kabaddi-secondary-color);
          font-size: 1rem;
          padding-bottom: var(--half-space);
          font-family: $font-bold;
          border-bottom: 0.05rem solid rgba(var(--black-color-rgb), 0.2);
        }

        .player-name {
          color: rgba(var(--black-color-rgb), 0.6);
          font-size: 1rem;
          line-height: 1.3rem;
          font-family: $font-bold;
        }

        &::after {
          top: 100%;
          left: 16%;
          border: solid rgba(0, 0, 0, 0);
          content: "";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-top-color: var(--white-color);
          border-width: 0.5rem;
        }
      }

      .team-mat-title {
        font-size: 1.2rem;
        font-family: $font-bold;
        color: var(--white-color);
        margin: 0 var(--half-space);
      }
    }

    .graph-box {
      position: relative;
      margin: 0 auto;
      width: 3.3rem;
      height: 3.3rem;
      @include flex-config(flex, null, center, center);

      svg {
        width: 100%;
        height: 100%;
        overflow: visible;
        filter: drop-shadow(0 0.3rem 0.1rem rgba(var(--black-color-rgb), 0.2));
      }

      .circle {
        &-inner {
          stroke-width: 3;
          stroke: rgba(var(--white-color-rgb), 0.8);
        }

        &-outer {
          animation: circle-chart-fill 2s reverse;
          stroke-width: 3;
          transform: rotate(-90deg);
          transform-origin: center;
          stroke-linecap: round;
          stroke: var(--kabaddi-accent-color);
        }
      }

      [stroke-dasharray="0,100"] {
        stroke-linecap: unset;
      }
    }
  }

  .completed {
    .status {
      background-color: var(--recent-color);
    }
  }

  .upcoming {
    .status {
      background-color: var(--upcoming-color);
      color: var(--kabaddi-secondary-color);
    }
  }

  .live {
    .status {
      background-color: var(--live-color);
    }
  }

  // Animation-code
  @-moz-keyframes circle-chart-fill {
    to {
      stroke-dasharray: 0 100;
    }
  }

  @-o-keyframes circle-chart-fill {
    to {
      stroke-dasharray: 0 100;
    }
  }

  @-webkit-keyframes circle-chart-fill {
    to {
      stroke-dasharray: 0 100;
    }
  }

  @keyframes circle-chart-fill {
    to {
      stroke-dasharray: 0 100;
    }
  }

  .live-match-footer {
    display: none;
  }
}

@media screen and (min-width: $tablet-min-width) {
  .waf-kabaddiscorecard {
    .card-section {
      background: var(--kabaddi-secondary-color) url("/static-assets/images/cssimages/mast-head-bg-web.png?v=#{$image-version}")
        no-repeat center center;
      background-size: 100% 100%;
      @include thumb-clip(75%, 95%);

      .card {
        &-wrap {
          // width: var(--container-max-width);
          margin: auto;
        }

        &-header {
          background-color: transparent;
          // border: none;
        }

        &-content {
          background: transparent;
          height: 12rem;
          border-radius: 6.5rem;
          margin: var(--full-space);
          .team-a {
            padding-right: 1rem;
          }
          .team-b {
            padding-left: 1rem;
          }
        }

        // &-footer {
        //   border: none;
        // }
      }

      .team {
        height: 100%;
        flex-basis: 45%;
        &-logo {
          width: 12rem;
        }

        &-a {
          @include border-radius(6.5rem 0 0 6.5rem, unset);

          .team-info {
            padding-right: 2rem;
            @include flex-config(null, row-reverse, null, center);
          }
          .team-name {
            text-align: right;
            padding-right: var(--full-space);
          }
        }

        &-b {
          @include border-radius(0 6.5rem 6.5rem 0, unset);

          .team-info {
            padding-left: 2rem;
            @include flex-config(null, row-reverse, null, center);
          }

          .team-name {
            order: 0;
            text-align: left;
            padding-left: var(--full-space);
          }

          .team-logo {
            order: 1;
          }
        }

        &-name {
          font-size: 2rem;
          color: var(--white-color);
          &.shortname {
            display: none;
          }
        }
      }

      .team-score {
        .score {
          font-size: 5rem;
        }
      }

      .graph-box {
        width: 5.5rem;
        height: 5.5rem;
      }

      .team-timer {
        flex-basis: 10%;
        max-width: 10%;
        .timer {
          font-size: 1.4rem;
          width: 6rem;
          height: 6rem;
        }
      }
    }
  }
}
